import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~6],
		"/(authenticated)/(producer)/assortment": [~8,[2,4]],
		"/(authenticated)/(producer)/assortment/categories": [~9,[2,4]],
		"/(authenticated)/(producer)/dashboard": [10,[2,4]],
		"/(authenticated)/(merchant)/merchant": [7,[2,3]],
		"/(authenticated)/(producer)/newsfeed": [~11,[2,4]],
		"/(authenticated)/(producer)/newsfeed/create": [~13,[2,4]],
		"/(authenticated)/(producer)/newsfeed/[id]": [~12,[2,4]],
		"/(authenticated)/(producer)/orders": [~14,[2,4,5]],
		"/(authenticated)/(producer)/orders/[id]": [~15,[2,4,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';